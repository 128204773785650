/* App.css */

:root {
    --dark-bg: #333;
    --green-accent: #64ffda;
    --light-green: #80cbc4;
    --green-gradient-start: #0f0f0f;
    --green-gradient-end: #262626;
}

/* Main Content Styles */
.main-content {
    background: linear-gradient(to bottom, var(--green-gradient-start), var(--green-gradient-end));
    padding: 5rem 1rem;
}

.main-content h1 {
    color: var(--green-accent);
    font-size: 4rem;
    margin-bottom: 2rem;
}

.main-content p {
    color: var(--light-green);
    font-size: 1.25rem;
    margin-bottom: 3rem;
}

/* Code snippet styles */
.code-snippet {
    background-color: #333;
    color: #fff;
    padding: 20px;
    border-radius: 5px;
    margin: 20px 0;
    font-family: 'Courier New', Courier, monospace;
    overflow-x: auto;
}

/* Button and Link Styles */
.button {
    background: var(--dark-bg);
    color: var(--green-accent);
    border: 1px solid var(--green-accent);
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s;
    text-decoration: none;
    display: inline-block;
}

.button:hover {
    background: var(--green-accent);
    color: var(--dark-bg);
}

/* Utility Classes */
.text-center {
    text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
    .main-content h1 {
        font-size: 2rem;
    }

    .main-content p {
        font-size: 1rem;
    }
}
